import React from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'
import logo from './logo.svg'

const linkStyle = {
  margin: `.625rem`,
}

const Header = () => (
  <header
    css={{
      display: `grid`,
      gridColumnGap: rhythm(1),
      gridRowGap: rhythm(1),
      gridTemplateColumns: `2fr 1fr `,
      alignItems: `center`,
      paddingTop: rhythm(1),
    }}
  >
    <Link className="link-style" to={'/'}>
      <img
        src={logo}
        alt={`Chris Feddersen's Logo`}
        css={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          height: rhythm(2),
          width: rhythm(2),
          display: `block`,
        }}
      />
    </Link>
    <nav css={{ textAlign: `right` }}>
      <Link
        to={'/projects'}
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        Projects
      </Link>
      <Link
        to={'/articles'}
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        Articles
      </Link>
      <Link
        to={'/about'}
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        About
      </Link>
    </nav>
  </header>
)

export default Header

import React from 'react'
import { FaTwitter, FaCodepen, FaGithub, FaDribbble } from 'react-icons/fa'
import { rhythm } from '../utils/typography'

const linkStyle = {
  margin: `.625rem`,
}

const Footer = () => (
  <footer
    css={{
      display: `grid`,
      gridColumnGap: rhythm(1),
      gridRowGap: rhythm(1),
      gridTemplateColumns: `1fr 2fr `,
      alignItems: `center`,
      paddingTop: rhythm(1),
      paddingBottom: rhythm(1),
    }}
  >
    <small to={'/'}>
      Get in touch via <a href="mailto:chris.feddo@gmail.com?subject=Message from website">Email</a>
    </small>
    <nav css={{ textAlign: `right` }}>
      {/* replace with GA later */}

      {/* <OutboundLink
        href="https://github.com/gatsbyjs/gatsby"
        title="GitHub"
        css={{
          ...navItemStyles,
          ...socialIconsStyles,
        }}
      >
        <GithubIcon style={{ verticalAlign: `text-top` }} />
      </OutboundLink> */}

      <a
        href="https://twitter.com/chrisfeddo"
        alt="Twitter"
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        <FaTwitter />
      </a>
      <a
        href="https://github.com/cfed"
        alt="Github"
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        <FaGithub />
      </a>
      <a
        href="https://codepen.io/cfed"
        alt="Codepen"
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        <FaCodepen />
      </a>
      <a
        href="https://dribbble.com/chrisfeddo"
        alt="Dribble"
        className="link-style"
        css={{
          ...linkStyle,
        }}
      >
        <FaDribbble />
      </a>
    </nav>
  </footer>
)

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import { StaticQuery, graphql } from 'gatsby'
import presets from '../utils/presets'
import { rhythm } from '../utils/typography'
import './layout.css'
require('prismjs/themes/prism.css')


const Layout = ({ location, children }) => (
  <StaticQuery
    query={graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `}
    render={data => (
      <div css={{
        backgroundColor: `white`,
        minHeight: `100vh`,
      }}>
        <div
          css={{
            width: `80%`,
            maxWidth: '66em',
            display: `grid`,
            margin: `0 auto`,
            gridColumnGap: rhythm(1),
            gridRowGap: rhythm(1),
            gridTemplateColumns: `1fr`,
            [presets.Hd]: {
              // maxWidth: '58em',
              gridRowGap: rhythm(2),
            },
          }}
        >
          <Helmet
            title={data.site.siteMetadata.title}
              meta={[
                { name: 'title', content: 'Chris Feddersen' },
                { name: 'description', content: 'UI/UX Designer and Developer' }
              ]}
          >
            <html lang="en" />
          </Helmet>
          <Header />
          <main>
            {children}
          </main>
          <Footer />
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
